/**
 * Events calendar
 */

import { trapTabs } from "./utilities";

function toggleFiltersMenu({ target }) {
    const filtersMenu = document.querySelector('.events-calendar-filters-menu');

    if (target.matches('.events-calendar-filters-toggle') || target.matches('.events-calendar-filters-close button')) {
        document.querySelector('.events-calendar-filters-toggle').classList.toggle('open');
        filtersMenu.classList.toggle('open');
        document.querySelector('body').classList.toggle('events-calendar-menu-open');

        if (window.isSmall) {
            trapTabs(filtersMenu);
        }

        return;
    }

    // close filter menu when clicking outside of it
    const filtersToggle = document.querySelector('.events-calendar-filters-toggle');

    if (
        !filtersToggle?.matches('.open') ||
        target.matches('.events-calendar-filters-toggle') ||
        target.matches('.events-calendar-filters-menu') ||
        target.matches('.events-calendar-filters-menu *')
    ) {
        return;
    }

    filtersToggle.classList.remove('open');
    filtersMenu.classList.remove('open');
    document.querySelector('body').classList.remove('events-calendar-menu-open');
};

function filterToggleButtons(event) {
    const { target, code } = event;

    if (target.matches('.events-calendar-toggle') && ['Enter', 'Space'].includes(code)) {
        event.preventDefault();
        target.click();
    }
}

function handleToggleButtonClick({ target }) {
    if (target.matches('.events-calendar-toggle')) {
        target.classList.toggle('active');
        updateToggleButtons(target.dataset.tagValue);
    }
}

// Make sure the corresponding tag toggle buttons on desktop and mobile have the same state
function updateToggleButtons(value) {
    if (value) {
        const toggleInput = document.querySelector(`.events-calendar-toggle input[value="${ value }"]`);
        toggleInput.checked = !toggleInput.checked;
        document.querySelector('form.events-calendar-form').requestSubmit();
        return;
    }

    document.querySelectorAll('.events-calendar-toggle__mobile').forEach((element) => {
        const { tagValue } = element.dataset;
        const toggleInput = document.querySelector(`.events-calendar-toggle input[value="${ tagValue }"]`);

        if (toggleInput.checked) {
            element.classList.add('active');
        } else {
            element.classList.remove('active');
        }
    });

    // check the All filters checkbox if no filters are selected
    const allFiltersButton = document.querySelector('.events-calendar-checkbox--all input');

    if (allFiltersButton) {
        const x = document.querySelectorAll('.events-calendar-toggle input:checked, .events-calendar-filters-menu label:not(.events-calendar-checkbox--all) input:checked').length
        allFiltersButton.checked = !document.querySelectorAll('.events-calendar-toggle input:checked, .events-calendar-filters-menu label:not(.events-calendar-checkbox--all) input:checked').length;
    }
}

function todayExpandable({ target }) {
    if (!target.matches('#today-events-more')) return;

    document.querySelector('#today-events-list').classList.add('active');
    target.remove();
}

function handleFilterClear(event) {
    const { target } = event;

    if (!target.matches('.events-calendar-checkbox--all') && !target.closest('.events-calendar-checkbox--all')) return;

    event.preventDefault();

    const allCheckbox = target.matches('input') ? target : target.querySelector('input');
    allCheckbox.checked = true;

    document.querySelectorAll('.events-calendar-toggle input[type="checkbox"], .events-calendar-filters-menu ul label.checkbox:not(.events-calendar-checkbox--all) input').forEach((element) => {
        element.checked = false;

        const toggleLabel = element.closest('.events-calendar-toggle');
        if (toggleLabel) {
            toggleLabel.classList.remove('active');
        }
    });

    target.closest('form.events-calendar-form').requestSubmit();
}

function handleFormChange({ target }) {
    updateToggleButtons();
    target.closest('form.events-calendar-form').requestSubmit();
}

export default function setupCalendar() {
    document.addEventListener('click', toggleFiltersMenu);
    document.addEventListener('click', todayExpandable);
    document.addEventListener('keydown', filterToggleButtons);
    document.addEventListener('click', handleToggleButtonClick);
    document.addEventListener('click', handleFilterClear);
    document.querySelector('.events-calendar-form')?.addEventListener('change', handleFormChange);
    updateToggleButtons();
}